@font-face {
	font-family: "SST";
	src: url("./assets/fonts/SSTLight.TTF") format("truetype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "SST";
	src: url("./assets/fonts/SSTRg.TTF") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "SST";
	src: url("./assets/fonts/SSTMedium.TTF") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "SST";
	src: url("./assets/fonts/SSTBold.TTF") format("truetype");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "SST";
	src: url("./assets/fonts/SSTRgIt.TTF") format("truetype");
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "SSTPro";
	src: url("./assets/fonts/SSTPro-Bold.otf");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "SSTPro";
	src: url("./assets/fonts/SSTPro-BoldItalic.otf");
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: "SSTPro";
	src: url("./assets/fonts/SSTPro-Italic.otf");
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "SSTPro";
	src: url("./assets/fonts/SSTPro-Roman.otf");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "SSTPro";
	src: url("./assets/fonts/SSTPro-Light.otf");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "SSTPro";
	src: url("./assets/fonts/SSTPro-LightItalic.otf");
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: "SSTPro";
	src: url("./assets/fonts/SSTPro-Medium.otf");
	font-weight: 500;
	font-style: normal;
}

* {
	box-sizing: border-box;
}
#root {
	/* --dsThemeColor: #1ca0a9; */
	--dsThemeColor: #04828B;
	--minMainHeight: 840px;
	--zIndexNavBar:50;
	--zIndexNavBarBackground:10;
	--zIndexglobalSearch:100;
	--zIndexAssetPopUp:250;
	--zIndexEmailPopUp:350;
	--zIndexLogoutPopUp:450;
	--headerHeight:65px;
	--sideBarCollapsedWidth:65px;
	--sideBarOpenWidth:290px;
	--zIndexBackToTopButton:20;
	/* --sideBarScrollHeight: calc(100vh - var(--headerHeight) - 60px); */
	display: grid;
	grid-template-rows: var(--headerHeight) minmax(var(--minMainHeight), auto) auto 1fr;
	grid-template-columns: 1fr;
	grid-template-areas:
		"header"
		"main"
		"seperator"
		"footer";
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
	font-family: "SSTPro", sans-serif;
	font-weight: 500;
}
p {
	font-family: "SSTPro", sans-serif;
	font-weight: 400;
}
.container {
	max-width: 1200px;
	margin-inline: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.horizontal-ruler {
	height: 2px;
	background-color: var(--dsThemeColor);
	border: 0px;
	margin-block: 10px 24px;
	padding: 0.1px;
}
.error-fallback{
	display: flex;
	align-items: start;
	justify-content: center;
	height: fit-content;
}
body {
	margin: 0;
	font-family: "SSTPro", sans-serif !important;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #f1f1f1 !important;
}

.backdrop {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(26, 30, 35, 0.6);
	z-index: var(--zIndexNavBarBackground);
	left: 0;
	top: 0;
	position: fixed;
	min-height: var(--minMainHeight);
	height: 100%;
	/* width: calc(100vw - 15px); */
	width: 100%;
	backdrop-filter: blur(2px);
	visibility: hidden;
}
.backdrop--show {
	visibility: visible;
	width: 100%;
}
.dummyDiv {
	visibility: hidden;
}
.image-wrapper {
	/* width: 100%;
	height: 100%; */
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.contained-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
	max-width: 100%;
	max-height: 100%;
}
.mui-icon {
	line-height: 0px;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}
.btn-none{
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}
/* Bootstrap CSS */

.btn {
	display: inline-block;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.45rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}

.btn:hover,
.btn:focus {
	text-decoration: none;
}

.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
	opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
	background-image: none;
}
.btn-primary {
	color: #fff;
	background-color: var(--dsThemeColor);
	border-color: var(--dsThemeColor);
}

.btn-primary:hover {
	color: #fff;
	background-color: #0069d9;
	border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #0062cc;
	border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
	color: #fff;
	background-color: #bfb50d;
	border-color: #bfb50d;
}

.btn-secondary:hover {
	color: #fff;
	background-color: #414025;
	border-color: #414025;
}

.btn-secondary:focus,
.btn-secondary.focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #545b62;
	border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
	color: #fff;
	background-color: #198754;
	border-color: #198754;
}

.btn-success:hover {
	color: #fff;
	background-color: #fff;
	border-color: #157347;
}

.btn-success:focus,
.btn-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(60, 153, 110);
}

.btn-success.disabled,
.btn-success:disabled {
	color: #fff;
	background-color: #198754;
	border-color: #198754;
}

