.unAuthContainer{
    flex-direction: column;
    margin-top: 10%;
}
.unAuthContainer h1{
    color: rgb(197, 48, 78);
    font-weight: bolder;
    
}
.unAuthContainer h2{
    font-size: 25px;
    color: var(--dsThemeColor);
    font-weight: bold;
}
.unAuthContainer p{
    margin-bottom: 0;
}